import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

type locale = 'es' | 'en';

const localeMap = {
    'es': 'es-ES',
    'en': 'en-US',
}

@Injectable()
export class TranslatableModule {
    constructor(
        private readonly translate: TranslateService,
    ) {
        this.translate.addLangs(['en', 'es']);
        this.translate.setDefaultLang('es');
        this.translate.use(this.locale);
    }

    get locale(): locale {
        return (this.translate.getBrowserLang() || this.translate.defaultLang) as locale;
    }

    get translateLocale(): string {
        return localeMap[this.locale] ?? 'es-ES';
    }
}