import { Component } from '@angular/core';

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.sass']
})
export class CalendarDialogComponent {
  constructor() { }

}
