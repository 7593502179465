import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionDTO, UpgradeType } from 'src/models/subscription/Subscription.dto';
import { Team } from 'src/models/teams/team';
import { PaymentService } from 'src/services/Payment/payment.service';
import { SessionManagerService } from 'src/services/SessionManager/session-manager.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './dialog-upgrade.component.html',
  styleUrls: ['./dialog-upgrade.component.sass']
})
export class DialogUpgradeComponent implements OnInit {
  constructor(
    private paymentService: PaymentService,
    private sessionManager: SessionManagerService,
    private dialogRef: MatDialogRef<DialogUpgradeComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: UpgradeType,
  ) {

  }

  output: string = "";
  teamSubscription?: SubscriptionDTO;
  team: Team = this.sessionManager.getTeam();

  ngOnInit() {
    this.paymentService.getSubscription(this.team.id).subscribe(subscription => this.teamSubscription = subscription);
    this.output = this.data;
  }

  close() {
    this.dialogRef.close(true);
  }
}

