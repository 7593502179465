import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ItemActivityDTO } from 'src/models/activities/itemActivity';


@Component({
  selector: 'content-notification[notifications]',
  templateUrl: './content-notification.component.html',
  styleUrls: ['./content-notification.component.sass']
})

export class ContentNotificationComponent {

  constructor(
  ) { }
  @Input() notifications!: ItemActivityDTO[];

  titolNotification: string = " ";

  public urlChanged = new Subject();
  isHidden: boolean = true;

}