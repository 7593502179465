<div class="calendar">
  <div class="header">
    <button class="change" (click)="changesDays(-1)">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <p class="month">{{ date | date : "MMMM" }}</p>
    <button mat-icon-button class="change" (click)="changesDays(1)">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
  <div class="buttonsViews">
    <button (click)="jumpCurrentDay()" *ngIf="!mesActual">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button (click)="swapView('mes')">
      <p>{{ "view.month" | translate }}</p>
    </button>
    <button (click)="swapView('semana')">
      <p>{{ "view.week" | translate }}</p>
    </button>
    <button (click)="swapView('dia')">
      <p>{{ "view.day" | translate }}</p>
    </button>
    <button (click)="hoursOfDay()">
      <p>Cambiar Hora</p>
    </button>
  </div>
  <ng-container [ngSwitch]="vistaCalendario">
    <ng-container *ngSwitchCase="'mes'" [ngTemplateOutlet]="mes" />
    <ng-container *ngSwitchCase="'semana'" [ngTemplateOutlet]="semana" />
    <ng-container *ngSwitchCase="'dia'" [ngTemplateOutlet]="dia" />
  </ng-container>
</div>


<ng-template #weekdays>
  <div class="weekdays">
    <div class="weekday">{{ "days.monday" | translate }}</div>
    <div class="weekday">{{ "days.tuesday" | translate }}</div>
    <div class="weekday">{{ "days.wednesday" | translate }}</div>
    <div class="weekday">{{ "days.thursday" | translate }}</div>
    <div class="weekday">{{ "days.friday" | translate }}</div>
    <div class="weekday">{{ "days.saturday" | translate }}</div>
    <div class="weekday">{{ "days.sunday" | translate }}</div>
  </div>
</ng-template>


<ng-template #mes>
  <ng-container [ngTemplateOutlet]="weekdays" />
  <div class="monthDays" cdkDropListGroup>
    <div *ngFor="let day of dias" class="monthDay-header" [cdkDropListData]="day.items" cdkDropList
      (cdkDropListDropped)="drop($event)" [ngClass]="{
          mesDiferente: day.date.getMonth() != date.getMonth(),
          todayMonth: day.date.getDate() === date.getDate()
        }">
      {{ day.date.getDate() }}
      <div class="dayItems">
        <div *ngFor="let item of day.items" cdkDrag>
          <button class="itemTask" (click)="openItem(item)">
            <p>{{item.name}}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #semana>
  <div class="week">
    <div class="hours">
      <div *ngFor="let hour of hours" class="hour" [ngClass]="{hourNow: hourNow === this.date.toString()}">
        {{ hour }}
      </div>
    </div>
    <div class="weekView">
      <ng-container [ngTemplateOutlet]="weekdays" />
      <div class="monthDays week" cdkDropListGroup>
        <div *ngFor="let day of getWeekDays(date)" class="day-header"
          [ngClass]="{ todayWeek: day.date.getDate() === date.getDate() }">
          <span>{{ day.date.getDate() }}</span>
          <div [attr.date]="day.date" [cdkDropListData]="day.items" cdkDropList (cdkDropListDropped)="drop($event)"
            cdkDropListOrientation="horizontal" class="weekDay">
            <div class="item" *ngFor="let item of day.items" [style]="getItemStyle(day.items, item)"
              (cdkDragEnded)="dragEnd($event, item, day.date)" (click)="openItem(item)" cdkDrag [attr.item]="item"
              mwlResizable [resizeSnapGrid]="{ bottom: 13.5 }" [enableGhostResize]="true"
              (resizeEnd)="onResizeItem($event, item)">
              <div class="itemBody" cdkDragHandle>
                <p>{{item.name}}</p>
              </div>

              <div class="partOfItem" mwlResizeHandle [resizeEdges]="{ bottom: true }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dia>
  <div class="weekdays">
    <div class="weekday">{{ date | date : "EEEE d" }}</div>
  </div>
  <div class="viewDay">
    <div class="viewDayHoras">
      <div *ngFor="let hour of hours" class="hour">
        {{ hour }}
      </div>
    </div>
    <div class="viewDayHoy">
      <div *ngFor="let day of getDay(date)" [cdkDropListData]="day.items" cdkDropList
        (cdkDropListDropped)="drop($event)" cdkDragPlaceholder cdkDropListOrientation="horizontal" class="weekDay">
        <div class="item" *ngFor="let item of day.items" [style]="getItemStyle(day.items, item)"
          (cdkDragEnded)="dragEnd($event, item, day.date)" (click)="openItem(item)" cdkDrag [attr.item]="item"
          mwlResizable [resizeSnapGrid]="{ bottom: 13.5 }" [enableGhostResize]="true"
          (resizeEnd)="onResizeItem($event, item)">
          <div class="itemBody" cdkDragHandle>
            <p>{{item.name}}</p>
          </div>

          <div class="partOfItem" mwlResizeHandle [resizeEdges]="{ bottom: true, right: true }"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>