import { Component } from '@angular/core';

@Component({
  selector: 'bee-add-project-icon',
  template: `
    <svg
      width="40"
      height="39"
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="9" width="30" height="30" rx="10" fill="#DADAF0" />
      <path
        d="M31 1.5C26.8577 1.5 23.5 4.85775 23.5 9C23.5 13.1422 26.8577 16.5 31 16.5C35.1422 16.5 38.5 13.1422 38.5 9C38.5 4.85775 35.1422 1.5 31 1.5ZM34.75 9C34.75 9.41421 34.4142 9.75 34 9.75H32.75C32.1977 9.75 31.75 10.1977 31.75 10.75V12C31.75 12.4142 31.4142 12.75 31 12.75C30.5858 12.75 30.25 12.4142 30.25 12V10.75C30.25 10.1977 29.8023 9.75 29.25 9.75H28C27.5858 9.75 27.25 9.41421 27.25 9C27.25 8.58579 27.5858 8.25 28 8.25H29.25C29.8023 8.25 30.25 7.80228 30.25 7.25V6C30.25 5.58579 30.5858 5.25 31 5.25C31.4142 5.25 31.75 5.58579 31.75 6V7.25C31.75 7.80228 32.1977 8.25 32.75 8.25H34C34.4142 8.25 34.75 8.58579 34.75 9Z"
        fill="#5755CE"
      />
      <g clip-path="url(#clip0_491_92)">
        <path
          d="M5 24.7589C5.09975 24.6119 5.20893 24.4715 5.32685 24.3387C5.96498 23.7005 6.58755 23.0469 7.27237 22.4554C7.94892 21.8318 8.82742 21.4727 9.74708 21.4437C9.95927 21.4227 10.173 21.4227 10.3852 21.4437L10.2763 21.6149C9.48063 22.7238 8.75796 23.8831 8.11284 25.0858C8.08951 25.1335 8.05057 25.1719 8.00245 25.1946C7.95434 25.2172 7.89994 25.2228 7.84825 25.2103C7.26257 25.1518 6.67112 25.2046 6.10506 25.3659H5.96498C5.46693 25.4749 5.29572 25.3659 5.06226 24.9612L5 24.7589Z"
          fill="#A1A1DD"
        />
        <path
          d="M15.0389 35.0001C14.6343 34.7667 14.572 34.611 14.6343 34.1285C14.7217 33.6139 14.7737 33.0939 14.7899 32.5721C14.7899 32.1986 14.9144 32.0741 15.179 31.9029C16.1907 31.2647 17.2023 30.611 18.1985 29.9418C18.3091 29.8795 18.4133 29.8065 18.5097 29.7239C18.5307 29.9465 18.5307 30.1705 18.5097 30.3931C18.4627 31.3547 18.0894 32.2715 17.4514 32.9924C16.8755 33.5994 16.2685 34.1908 15.6615 34.7822C15.5614 34.86 15.4576 34.9328 15.3502 35.0001H15.0389Z"
          fill="#A1A1DD"
        />
        <path
          d="M19.0078 15.9495C19.277 17.2086 19.9103 18.3608 20.829 19.2629C21.7477 20.1651 22.9113 20.7772 24.1751 21.0234C23.895 21.5526 23.6459 22.1285 23.3502 22.5798C22.1021 24.7481 20.4481 26.6557 18.4786 28.1985C17.4337 29.0469 16.3426 29.8367 15.2101 30.5642C15.1955 30.5808 15.1776 30.5941 15.1574 30.6032C15.1373 30.6122 15.1155 30.6169 15.0934 30.6169C15.0713 30.6169 15.0495 30.6122 15.0294 30.6032C15.0092 30.5941 14.9913 30.5808 14.9767 30.5642L13.8249 29.3191C13.7982 29.2802 13.7838 29.2341 13.7838 29.1868C13.7838 29.1396 13.7982 29.0934 13.8249 29.0545C13.9952 28.6295 14.0747 28.1736 14.0584 27.716C14.0546 27.4802 14.0031 27.2477 13.9069 27.0324C13.8107 26.8172 13.6718 26.6237 13.4987 26.4636C13.3255 26.3036 13.1217 26.1803 12.8996 26.1013C12.6774 26.0223 12.4416 25.9892 12.2062 26.0039C11.797 26.0054 11.3913 26.0791 11.0078 26.2218C10.9686 26.2548 10.919 26.2728 10.8677 26.2728C10.8165 26.2728 10.7669 26.2548 10.7276 26.2218L9.63815 25.1323C9.54477 25.1323 9.54477 24.9767 9.63815 24.8678C10.9342 22.6329 12.555 20.603 14.4475 18.8444C15.765 17.6649 17.2625 16.7033 18.8833 15.9962L19.0078 15.9495ZM20.8288 22.1751C20.7748 21.434 20.4422 20.7407 19.898 20.2347C19.3537 19.7286 18.6381 19.4473 17.895 19.4473C17.1518 19.4473 16.4362 19.7286 15.8919 20.2347C15.3477 20.7407 15.0151 21.434 14.9611 22.1751C15.0151 22.9163 15.3477 23.6096 15.8919 24.1156C16.4362 24.6217 17.1518 24.9029 17.895 24.9029C18.6381 24.9029 19.3537 24.6217 19.898 24.1156C20.4422 23.6096 20.7748 22.9163 20.8288 22.1751Z"
          fill="#A1A1DD"
        />
        <path
          d="M20.1595 15.5294C21.2133 15.1998 22.3086 15.0217 23.4125 15.0002H24.3619C24.4422 14.9981 24.5221 15.0123 24.5967 15.0421C24.6713 15.0718 24.7391 15.1164 24.7959 15.1732C24.8527 15.23 24.8973 15.2978 24.927 15.3724C24.9568 15.447 24.971 15.5269 24.9689 15.6072C25.0635 16.9735 24.932 18.346 24.5798 19.6695C24.5798 19.6695 24.5798 19.7784 24.5798 19.8407C23.4752 19.6984 22.4468 19.201 21.6495 18.4234C20.8522 17.6458 20.3293 16.6301 20.1595 15.5294Z"
          fill="#A1A1DD"
        />
        <path
          d="M11.9728 27.1714C12.5798 27.1714 12.8288 27.3893 12.8288 27.8873C12.8211 28.1802 12.7551 28.4687 12.6349 28.7359C12.5147 29.0031 12.3425 29.2437 12.1284 29.4438C11.1592 30.4884 10.0729 31.418 8.89105 32.2142L8.28405 32.5566C8.17363 32.645 8.03639 32.6932 7.89494 32.6932C7.75349 32.6932 7.61626 32.645 7.50584 32.5566C7.41743 32.4462 7.36926 32.3089 7.36926 32.1675C7.36926 32.026 7.41743 31.8888 7.50584 31.7784C7.93137 30.9467 8.45381 30.1683 9.06226 29.4593C9.51362 28.9457 9.98055 28.4477 10.4475 27.9029C10.8518 27.4841 11.3931 27.2245 11.9728 27.1714Z"
          fill="#A1A1DD"
        />
        <path
          d="M11.3813 31.825L13.0623 30.2686L13.9961 31.2024L13.7938 31.3269L12.3775 32.1207C12.2945 32.1742 12.201 32.2091 12.1033 32.223C12.0056 32.237 11.9061 32.2297 11.8115 32.2016C11.7169 32.1735 11.6295 32.1253 11.5552 32.0603C11.481 31.9953 11.4217 31.915 11.3813 31.825Z"
          fill="#A1A1DD"
        />
        <path
          d="M8.11279 28.6188C8.03679 28.576 7.97062 28.5177 7.91859 28.4477C7.86655 28.3777 7.8298 28.2976 7.81073 28.2125C7.79165 28.1274 7.79067 28.0392 7.80785 27.9537C7.82503 27.8682 7.85999 27.7872 7.91046 27.7161C8.17505 27.1713 8.47077 26.6422 8.75092 26.1597L9.66921 26.9379L8.11279 28.6188Z"
          fill="#A1A1DD"
        />
        <path
          d="M17.8716 23.8718C17.5231 23.8717 17.1824 23.7682 16.8929 23.5742C16.6033 23.3803 16.3779 23.1047 16.2453 22.7824C16.1126 22.4601 16.0787 22.1057 16.1479 21.7641C16.217 21.4225 16.3861 21.1092 16.6336 20.8639C16.8811 20.6185 17.1959 20.4523 17.5381 20.3862C17.8803 20.3201 18.2344 20.3571 18.5555 20.4926C18.8766 20.6281 19.1502 20.856 19.3415 21.1473C19.5329 21.4385 19.6334 21.7801 19.6304 22.1286C19.6263 22.5923 19.4391 23.0357 19.1098 23.3621C18.7804 23.6886 18.3354 23.8718 17.8716 23.8718Z"
          fill="#A1A1DD"
        />
      </g>
      <defs>
        <clipPath id="clip0_491_92">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(5 15)"
          />
        </clipPath>
      </defs>
    </svg>
  `,
  standalone: true,
})
export default class AddProject {}
