import { Component } from '@angular/core';

@Component({
  selector: 'bee-logo-icon',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" height="56" width="82" viewBox="0 0 82 56" fill="none">
        <g clip-path="url(#clip0_116_270)">
        <path d="M13.4158 11.2844H32.9149C33.5546 11.2837 34.1832 11.453 34.7376 11.7753C35.292 12.0976 35.7527 12.5616 36.0734 13.1206L45.8026 30.2011C46.1216 30.7613 46.2895 31.3962 46.2895 32.0425C46.2895 32.6887 46.1216 33.3237 45.8026 33.8839L36.0328 50.9335C35.712 51.4926 35.2513 51.9566 34.697 52.2789C34.1426 52.6012 33.514 52.7705 32.8743 52.7698H13.3752C12.7348 52.7684 12.1059 52.5971 11.5516 52.2731C10.9973 51.949 10.537 51.4835 10.2168 50.9233L0.487524 33.8531C0.167627 33.2913 -0.000732422 32.6546 -0.000732422 32.0066C-0.000732422 31.3585 0.167627 30.7218 0.487524 30.16L10.2574 13.1104C10.5778 12.5518 11.0387 12.0888 11.5934 11.7681C12.148 11.4474 12.7768 11.2805 13.4158 11.2844Z" fill="url(#paint0_linear_116_270)"/>
        <path d="M54.9328 40.8802H61.5036C61.7115 40.8891 61.9137 40.9512 62.0914 41.0607C62.269 41.1701 62.4162 41.3233 62.5192 41.506L65.7995 47.261C65.9094 47.449 65.9673 47.6633 65.9673 47.8816C65.9673 48.0999 65.9094 48.3143 65.7995 48.5023L62.509 54.247C62.4016 54.437 62.2465 54.5949 62.0592 54.7048C61.8719 54.8147 61.6592 54.8727 61.4427 54.8728H54.9125C54.7046 54.8639 54.5024 54.8018 54.3247 54.6923C54.1471 54.5829 53.9999 54.4297 53.8969 54.247L50.6166 48.492C50.5116 48.3023 50.4565 48.0887 50.4565 47.8714C50.4565 47.6541 50.5116 47.4404 50.6166 47.2507L53.8258 41.506C53.9348 41.3083 54.096 41.1451 54.2915 41.0346C54.487 40.9241 54.709 40.8706 54.9328 40.8802Z" fill="url(#paint1_linear_116_270)"/>
        <path d="M55.5522 1.46111e-05H65.3932C65.7241 -0.00297712 66.0498 0.0828206 66.337 0.248639C66.6243 0.414457 66.8629 0.65435 67.0283 0.943798L71.9335 9.55069C72.0929 9.83363 72.1767 10.1536 72.1767 10.4791C72.1767 10.8046 72.0929 11.1245 71.9335 11.4075L66.9673 20.0041C66.8041 20.2857 66.5714 20.5196 66.292 20.6832C66.0125 20.8469 65.6959 20.9345 65.3729 20.9376H55.5319C55.2085 20.9365 54.8911 20.8497 54.6113 20.6859C54.3315 20.5221 54.0992 20.287 53.9375 20.0041L49.0322 11.387C48.8729 11.104 48.7891 10.7841 48.7891 10.4586C48.7891 10.133 48.8729 9.81311 49.0322 9.53018L53.9578 0.923281C54.12 0.641592 54.3527 0.407979 54.6326 0.245916C54.9125 0.0838521 55.2296 -0.000955209 55.5522 1.46111e-05Z" fill="url(#paint2_linear_116_270)"/>
        <path d="M78.6261 23.9639C78.0609 23.9804 77.5161 24.1811 77.0731 24.5361C76.6302 24.8911 76.3128 25.3813 76.1684 25.9336H52.0687C51.7229 25.929 51.3845 25.8315 51.0882 25.6512C50.792 25.471 50.5485 25.2144 50.3828 24.9077L40.0747 6.44238C39.7931 5.93287 39.3821 5.5084 38.8841 5.21269C38.386 4.91698 37.819 4.76071 37.2413 4.75998H22.4951C22.3679 4.23648 22.0707 3.77112 21.651 3.43826C21.2312 3.1054 20.7132 2.92423 20.1795 2.92371C19.7047 2.92828 19.242 3.07581 18.8507 3.34745C18.4593 3.61909 18.1571 4.00247 17.9826 4.44857C17.8081 4.89467 17.7694 5.3832 17.8713 5.85167C17.9732 6.32015 18.2111 6.74727 18.5546 7.07841C18.9996 7.51728 19.5983 7.76066 20.2202 7.75547C20.7063 7.74706 21.1785 7.58948 21.5739 7.30365C21.9694 7.01782 22.2694 6.61725 22.4341 6.15514H37.2413C37.5858 6.15942 37.9229 6.25683 38.2176 6.43723C38.5123 6.61763 38.7538 6.87444 38.917 7.18099L49.2251 25.6463C49.5084 26.1566 49.9211 26.5815 50.4209 26.8772C50.9207 27.1729 51.4895 27.3287 52.0687 27.3287H76.1684C76.3176 27.8918 76.646 28.3896 77.103 28.7455C77.56 29.1014 78.1201 29.2957 78.6972 29.2983C79.0412 29.2923 79.3808 29.2178 79.6964 29.0792C80.012 28.9406 80.2975 28.7406 80.5365 28.4905C80.7756 28.2405 80.9636 27.9453 81.0897 27.6219C81.2158 27.2984 81.2776 26.9531 81.2716 26.6055C81.2656 26.2579 81.1919 25.9149 81.0547 25.5962C80.9175 25.2774 80.7194 24.989 80.4719 24.7475C80.2243 24.506 79.9321 24.3162 79.6119 24.1888C79.2917 24.0613 78.9498 23.9989 78.6058 24.005L78.6261 23.9639ZM78.6972 27.934C78.3497 27.934 78.0165 27.7945 77.7708 27.5464C77.5251 27.2982 77.3871 26.9616 77.3871 26.6106C77.3871 26.2596 77.5251 25.923 77.7708 25.6749C78.0165 25.4267 78.3497 25.2873 78.6972 25.2873C79.0446 25.2873 79.3778 25.4267 79.6235 25.6749C79.8692 25.923 80.0072 26.2596 80.0072 26.6106C80.0072 26.9616 79.8692 27.2982 79.6235 27.5464C79.3778 27.7945 79.0446 27.934 78.6972 27.934Z" fill="#0D0AA9"/>
        <path d="M61.4527 32.6939C60.9211 32.7006 60.4071 32.8872 59.9928 33.2238C59.5786 33.5604 59.2881 34.0276 59.1677 34.5507H52.2719C51.886 34.5518 51.5071 34.6558 51.1737 34.8522C50.8402 35.0485 50.564 35.3302 50.3728 35.6689L41.4255 52.1953C41.0647 52.0908 40.6858 52.0658 40.3146 52.1221C39.9434 52.1783 39.5884 52.3144 39.2738 52.5213C38.9592 52.7282 38.6923 53.0009 38.4911 53.321C38.2899 53.6411 38.1592 54.0012 38.1077 54.3768C38.0563 54.7524 38.0853 55.1348 38.1929 55.4981C38.3005 55.8613 38.4841 56.197 38.7313 56.4824C38.9785 56.7677 39.2835 56.9961 39.6257 57.152C39.9679 57.308 40.3392 57.3878 40.7146 57.3862C41.2269 57.3738 41.7244 57.2106 42.146 56.9166C42.5677 56.6226 42.8952 56.2106 43.0883 55.7312C43.2813 55.2518 43.3316 54.7258 43.2329 54.218C43.1341 53.7101 42.8907 53.2425 42.5325 52.8724L51.5407 36.3152C51.6154 36.1865 51.7223 36.0799 51.8506 36.0061C51.979 35.9323 52.1242 35.8938 52.2719 35.8946H59.2083C59.3645 36.3707 59.6657 36.7847 60.0687 37.0776C60.4718 37.3706 60.9562 37.5274 61.4527 37.5257C62.0601 37.4857 62.6297 37.2137 63.0458 36.7651C63.462 36.3164 63.6936 35.7246 63.6936 35.1098C63.6936 34.495 63.462 33.9032 63.0458 33.4545C62.6297 33.0058 62.0601 32.7339 61.4527 32.6939ZM40.7451 56.0628C40.3976 56.0628 40.0644 55.9234 39.8187 55.6752C39.573 55.427 39.435 55.0904 39.435 54.7395C39.435 54.3885 39.573 54.0519 39.8187 53.8037C40.0644 53.5555 40.3976 53.4161 40.7451 53.4161C41.0926 53.4161 41.4258 53.5555 41.6715 53.8037C41.9172 54.0519 42.0552 54.3885 42.0552 54.7395C42.0552 55.0904 41.9172 55.427 41.6715 55.6752C41.4258 55.9234 41.0926 56.0628 40.7451 56.0628Z" fill="#0D0AA9"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_116_270" x1="37.5" y1="11" x2="-8.52347e-07" y2="67.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#05EEFE"/>
        <stop offset="1" stop-color="#1E6AFC"/>
        </linearGradient>
        <linearGradient id="paint1_linear_116_270" x1="63" y1="56" x2="53.5" y2="41" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1E6AFC"/>
        <stop offset="1" stop-color="#05EEFE"/>
        </linearGradient>
        <linearGradient id="paint2_linear_116_270" x1="54.5" y1="21" x2="69" y2="-3" gradientUnits="userSpaceOnUse">
        <stop stop-color="#05EEFE"/>
        <stop offset="1" stop-color="#1E6AFC"/>
        </linearGradient>
        </defs>
    </svg>
  `,
  styleUrls: ['logo.icon.sass'],
  standalone: true
})
export default class LogoIcon {}