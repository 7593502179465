import { animate, state, style, transition, trigger } from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalendarDialogComponent } from 'src/app/calendar/calendar-dialog/calendar-dialog.component';
import { NotificationsComponent } from 'src/app/notification/notifications/notifications.component';
import { DialogLabelsComponent } from 'src/app/project/dialog-labels/dialog-labels.component';
import { DialogConfirmDeleteComponent } from 'src/app/teams/dialog-confirm-delete/dialog-confirm-delete.component';
import { Team } from 'src/models/teams/team';
import { User } from 'src/models/user';
import { ItemActivityService } from 'src/services/ItemActivity/itemActivity.service';
import { SessionManagerService } from 'src/services/SessionManager/session-manager.service';
import { TeamService } from 'src/services/Team/team.service';
import { WebSocketService } from 'src/services/WebSocket/web-socket.service';
import { LateralMenu } from '../../../models/menu';
import { DialogUserEditComponent } from '../dialog-user-edit/dialog-user-edit.component';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'main-menu',
  templateUrl: 'main-menu.component.html',
  styleUrls: ['main-menu.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        'padding-left': 0,
        width: 0,
        opacity: 0,
      })),
      transition('* => *', animate('0.5s ease-in-out')),
    ]),
  ],
})
export class MainMenuComponent implements OnInit {
  constructor(
    private sessionManager: SessionManagerService,
    private activityService: ItemActivityService,
    private teamService: TeamService,
    private webSocketService: WebSocketService<any>,
    private platform: Platform,
    private dialog: MatDialog,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
  ) { }

  user?: User = this.sessionManager.getUser();
  unreadedNotifications: number = ItemActivityService.unreadedNotifications;
  visible: boolean = this.sessionManager.retrieve<boolean>('extendedLateralMenu') ?? true;
  contentWidth = 0;
  @ViewChild('menu') private readonly menu?: ElementRef;
  @ViewChild('horizontalMenu') private readonly horizontalMenu!: ElementRef;
  @ViewChild('drawer') private readonly drawer!: MatDrawer;

  teams: Team[] = [];
  _selectedTeam: Team = this.sessionManager.getTeam();

  get isMobile(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  get selectedTeam(): Team {
    return this._selectedTeam;
  }
  set selectedTeam(team: Team) {
    if (team && this._selectedTeam?.id !== team?.id) this.sessionManager.setTeam(team);
    this._selectedTeam = team;
    this.closeMenu();
  }

  get elementHeight(): number {
    return this.horizontalMenu.nativeElement.offsetHeight;
  }

  readonly menus: LateralMenu[] = [
    { name: 'lateralMenu.projects', path: '/project', icon: 'rocket' },
    { name: 'lateralMenu.teams', path: '/teams', icon: 'groups' },
    // { name: 'documents', path: '/documents', icon: 'description' },
    // { name: 'calendar', path: '/calendar', icon: 'calendar_month' },
    // { name: 'activity', path: '/activity', icon: 'account_tree' },
    // { name: 'reports', path: '/reports', icon: 'reports' },
    // { name: 'trash', path: '/trash', icon: 'delete' },
    //{ name: 'lateralMenu.calendar', path: '/calendar', icon: 'calendar_today' },
  ];

  ngOnInit(): void {
    this.sessionManager.userSession.subscribe(user => this.user = user);

    TeamService.teamsObserver.subscribe(teams => {
      if (!this.selectedTeam) this.selectedTeam = teams[0];
      else {
        const selectedTeam = teams.find(t => t.id === this.selectedTeam.id);
        if (selectedTeam) this.selectedTeam = selectedTeam;
      }
      this.teams = teams;
      this.changeDetector.detectChanges();
    });
    this.teamService.updateTeams();

    if (!this.selectedTeam) {
      const firstTeam = this.teamService.teams[0];
      if (firstTeam) this.selectedTeam = firstTeam;
    }
    SessionManagerService.selectedTeamObserver.subscribe(team => {
      this._selectedTeam = team;
      this.changeDetector.detectChanges();
    });

    ItemActivityService.unreadedNotificationsObserver.subscribe(res => {
      this.unreadedNotifications = res;
      this.changeDetector.detectChanges();
    });
    this.activityService.updateNotifications();
    this.handleWS();
  }

  ngAfterViewInit() {
    this.resizeContent();
    this.menu && new ResizeObserver(() => this.resizeContent()).observe(this.menu.nativeElement);
    SessionManagerService.setTopMenuHeight(this.elementHeight);
    if(this.isMobile) this.visible = true;
  }

  async handleWS() {
    await this.webSocketService.waitUntilOpen();
    this.webSocketService.subscribe((event) => {
      switch (event.type) {
        case 'addItemActivity':
          ItemActivityService.notification.unshift(event.activity);
          this.activityService.doUpdate(ItemActivityService.notification);
          break;
      }
    });
  }

  @HostListener('window:resize')
  private resizeContent() {
    this.contentWidth = window.innerWidth - (this.menu?.nativeElement.clientWidth ?? 0);
    this.changeDetector.detectChanges();
  }

  closeMenu() {
    this.drawer.close();
  }

  toggleMenu() {
    this.visible = !this.visible;
    this.sessionManager.store('extendedLateralMenu', this.visible);
  }

  openDialogEditUser() {
    this.dialog.open(DialogUserEditComponent);
  }

  openDialogLabels() {
    this.dialog.open(DialogLabelsComponent);
  }

  logout() {
    this.dialog.open(DialogConfirmDeleteComponent, { width: 'fit-content', data: 'modals.logoutMobile.logout' }).afterClosed().subscribe((result) => {
      if (result) {
        this.sessionManager.logOut();
        this.router.navigate(['/login']);
      }
    });
  }

  openDialog() {
    this.dialog.open(DialogUserEditComponent);
  }

  openNotifications() {
    this.dialog.open(NotificationsComponent);
  }

  openCalendar() {
    this.dialog.open(CalendarDialogComponent);
  }
}