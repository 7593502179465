import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogUpgradeComponent } from 'src/app/payments/upgrade/dialog-upgrade.component';
import { generateAuditory } from 'src/models/activities/itemActivity';
import { FileDTO } from 'src/models/attachments/attachment';
import { ItemDTO } from 'src/models/tasks/item';
import { User } from 'src/models/user';
import { AttachmentService } from 'src/services/Attachments/attachment.service';
import { PaymentService } from 'src/services/Payment/payment.service';
import { SessionManagerService } from 'src/services/SessionManager/session-manager.service';
import { Id } from 'src/VOs/Id';

@Component({
  selector: 'attachments[item][projectId]',
  templateUrl: 'attachments.component.html',
  styleUrls: ['attachments.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsComponent implements OnInit {
  constructor(
    private sessionManager: SessionManagerService,
    private attachmentService: AttachmentService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private paymentService: PaymentService
  ) { }

  @Input() projectId!: string;
  @Input() item!: ItemDTO;

  @Output() closeDialog = new EventEmitter();

  user: User = this.sessionManager.getUser()!;
  viewList: boolean = false;
  attachments: FileDTO[] = [];
  loadingAttachment: FileDTO = {
    id: 'loading',
    name: 'LOADING',
    type: 'loading',
    size: 0,
    auditory: {
      createdAt: 0,
      createdBy: '',
    },
  };

  ngOnInit() {
    this.updateAttachments();
  }

  updateAttachments() {
    this.attachmentService.getAllByItemId(this.item.id).subscribe({
      next: res => {
        this.attachments = res;
        this.changeDetector.detectChanges();
      }
    });
  }

  addAttachment(event: any) {
    const file: File = event.target.files[0];
    this.addFile(file);
    event.target.value = '';
  }

  addFile(file: File) {
    if (!file || this.attachments.filter(attachment => attachment.name === file.name).length > 0) {
      const fileRepeatTranslation = this.translate.instant('activities.filerepeat');
      const reuploadTranslation = this.translate.instant('activities.reupload');
      this.snackBar.open(fileRepeatTranslation, reuploadTranslation, { duration: 5000 })
        .onAction().subscribe(() => this.processUpload(file));
      return;
    };
    this.processUpload(file);
  }

  private processUpload(file: File) {
    const team = SessionManagerService.selectedTeam;
    if (!team) return;

    this.paymentService.getSubscription(team.id).subscribe(subscription => {
      if (subscription?.plan == 'free' || subscription == null && file.size > 1024 * 1024) {
        this.dialog.open(DialogUpgradeComponent, { data: 'filesize' }).afterClosed().subscribe(res => {
          res && this.closeDialog.emit();
        });
        return;
      }

      const fileId = Id.generate().value;
      this.item.projectId = this.projectId;
      this.attachmentService.create(this.item, fileId, file).subscribe({
        next: () => {
          this.attachments.push(this.loadingAttachment);
          const attachment: FileDTO = {
            id: fileId,
            name: file.name,
            type: file.type,
            size: file.size,
            auditory: generateAuditory(this.user)
          }
          const index = this.attachments.findIndex(attachment => attachment.id !== this.loadingAttachment.id);
          this.attachments[index] = attachment;

          this.changeDetector.detectChanges();
          this.updateAttachments();
        }
      });
    });
  }

  addFileDTO(file: FileDTO) {
    this.attachments.push(file);
  }

  switchViewMode() {
    this.viewList = !this.viewList;
  }

  delFileId(file: string) {
    this.attachments = this.attachments.filter(attachment => attachment.id !== file);
  }

  removeFile(file: FileDTO) {
    this.attachments = this.attachments.filter(attachment => attachment.id !== file.id);
    this.changeDetector.detectChanges();
  }
}
