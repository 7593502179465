import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslatableModule } from '../TranslatableModule';
import { BeeComponentsModule } from '../bee-components/bee-components.module';
import { ActivitiesComponent } from './activities/activities.component';
import { ContentNotificationComponent } from './content-notification/content-notification.component';
import { ItemActivityComponent } from './item-activity/item-activity.component';
import { ItemInputMessageComponent } from './item-input-message/item-input-message.component';
import { ItemMessageComponent } from './item-message/item-message.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ItemActivityService } from 'src/services/ItemActivity/itemActivity.service';

@NgModule({
  providers: [ ItemActivityService ],
  declarations: [
    ActivitiesComponent,
    ItemMessageComponent,
    NotificationsComponent,
    ItemActivityComponent,
    ItemInputMessageComponent,
    ContentNotificationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    BeeComponentsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/assets/i18n/notification/', '.json'),
        deps: [HttpClient]
      },
      isolate: false,
      extend: true,
    }),
  ],
  exports: [
    ActivitiesComponent,
    ItemMessageComponent,
    NotificationsComponent,
    ItemActivityComponent,
    ItemInputMessageComponent,
    ContentNotificationComponent,
  ]
})
export class NotificationModule extends TranslatableModule {
  constructor(translate: TranslateService) {
    super(translate);
  }
}