import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import Quill from 'quill';
import QuillCursors from 'quill-cursors';
import { Subscription } from 'rxjs';
import { ContextMenuComponent } from 'src/app/bee-components/contextmenu/contextmenu.component';
import { DialogLabelsComponent } from 'src/app/project/dialog-labels/dialog-labels.component';
import { DialogItemData, ItemDTO, ItemDate, ItemTime, Priority } from 'src/models/tasks/item';
import { User } from 'src/models/user';
import { AnalyticsService } from 'src/services/Analitycs/analitycs.service';
import { EventListenerService } from 'src/services/EventListener/eventListener.service';
import { ItemService } from 'src/services/Item/item.service';
import { SessionManagerService } from 'src/services/SessionManager/session-manager.service';
import { WebSocketService } from 'src/services/WebSocket/web-socket.service';
import { QuillBinding } from 'y-quill';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { environment } from '../../../environments/environment';
import { getRandomColorString } from '../../../utils/CssUtils';
import { DialogShareLinkComponent } from '../../projects/dialog-share-link/dialog-share-link.component';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { DialogUsersItemComponent } from '../dialog-users-item/dialog-users-item.component';
import { ItemGlobalTimeComponent } from '../item-global-time/item-global-time.component';
import { TagsComponent } from '../tags/tags.component';

@Component({
  selector: 'dialog-item',
  templateUrl: 'dialog-item.component.html',
  styleUrls: ['dialog-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogItemComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<DialogItemComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: DialogItemData,
    private itemService: ItemService,
    private snackBar: MatSnackBar,
    private webSocketService: WebSocketService<any>,
    private eventListenerService: EventListenerService,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.item = data.item;
    this.projectId = data.projectId;
    this.item.projectId = data.projectId;
    this._item = structuredClone(this.item);
  }

  @Output() deleteItem = new EventEmitter<ItemDTO>();

  @ViewChild('globalItemTime') globalItemTime?: ItemGlobalTimeComponent;
  @ViewChild('contextMenu') contextMenu?: ContextMenuComponent;
  @ViewChild('tags') tagsRef?: TagsComponent;
  @ViewChild('attachments') attachmentsRef?: AttachmentsComponent;

  menuPosition: { x: number; y: number } = { x: 40, y: 40 };

  item: ItemDTO;
  private _item: ItemDTO;
  projectId: string;
  usersSubscription?: Subscription;
  allUsers: User[] = SessionManagerService.selectedTeam?.members.map(member => member.user) ?? [];
  addedUsers: User[] = [];
  activeTimes: boolean = false
  activeForm: boolean = false;
  itemName: string = '';
  membersName: string = '';
  following: boolean = false;

  pasteEvent?: Subscription;
  provider?: WebsocketProvider;

  ngOnInit() {
    if (this.item) this.itemName = this.item.name;
    this.itemService.get(this.item.id).subscribe({
      next: item => {
        this.item = item;
        this.updateItemData();
      },
    });

    this.initQuill();
    this.updateItemData();
    this.handleWS();
    this.pasteEvent = this.eventListenerService.add<ClipboardEvent>('paste').subscribe(e => {
      const files = e.clipboardData?.files;
      if (!files) return;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.attachmentsRef?.addFile(file);
      }
    });
  }

  initQuill() {
    Quill.debug('error');
    Quill.register('modules/cursors', QuillCursors);
    const editor = new Quill('#editor-container', {
      modules: {
        cursors: {
          hideDelayMs: 5000,
          hideSpeedMs: 0,
          selectionChangeSource: null,
          transformOnTextChange: true,
        },
        toolbar: false,
        /*[
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['image', 'code-block']
        ],
        */
        history: {
          userOnly: true
        }
      },
      placeholder: 'Start collaborating...',
      theme: 'snow' // or 'bubble'
    });
    window.addEventListener('blur', () => editor.blur());

    const ydoc = new Y.Doc();
    this.provider = new WebsocketProvider(environment.wsCollabUrl, this.item.id, ydoc);
    this.provider.on('sync', (event: any) => {
      const oldValue = structuredClone(value);
      value = ytext.toString();
      if (oldValue + oldValue === value) ytext.delete(0, oldValue.length);
    });
    this.provider._resyncInterval = 5000;

    const ytext = ydoc.getText('description');
    let value = ytext.toString();
    new QuillBinding(ytext, editor, this.provider.awareness);
    const user = SessionManagerService.user;
    this.provider.awareness.setLocalStateField('user', {
      id: user?.id,
      name: user?.name,
      color: getRandomColorString(),
    });
  }

  updateItemData() {
    if (this.item) this.itemName = this.item.name;
    if (!this.item.time) {
      this.item.time = {
        spent: 0,
        total: 0,
      }
    }
    this.following = this.item.observers?.includes(SessionManagerService.user?.id ?? '') ?? false;
    this.updateClone();
    this.viewTimes();
    this.loadUsers();

    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.usersSubscription?.unsubscribe();
    this.close();
    this.webSocketService.leaveItemUpdates(this.projectId, this.item.id);
    this.pasteEvent?.unsubscribe();
    this.provider?.destroy();
  }

  readActivities() {
    if (this.item.userNotifications && this.item.userNotifications > 0) this.item.userNotifications = 0;
  }

  async handleWS() {
    await this.webSocketService.waitUntilOpen();
    this.webSocketService.joinItemUpdates(this.projectId, this.item.id);
    this.webSocketService.subscribe((event) => {
      switch (event.type) {
        case 'updateItemDescription':
          if (event.projectId === this.projectId && event.itemId === this.item.id) {
            this.item.description = event.data;
          }
          break;
        case 'addItemFile':
          this.attachmentsRef?.addFileDTO(event.file);
          break;
        case 'delItemFile':
          this.attachmentsRef?.delFileId(event.fileId);
          break;
      }
    });
  }

  followItem() {
    const user = SessionManagerService.user;
    if (!user) return;

    if (!this.item.observers) this.item.observers = [];

    if (!this.following) {
      this.item.observers.push(user.id);
      this.following = true;
    } else {
      this.item.observers = this.item.observers.filter(val => val !== user.id);
      this.following = false;
    }

    this.save(false);

    const translation = this.translate.instant(this.following ? 'task.followed' : 'task.unFollowed');
    this.snackBar.open(translation, "Ok", { duration: 2000 })
  }

  updateItemDescription() {
    this.webSocketService.sendItemDescriptionUpdate(this.projectId, this.item.id, this.item.description ?? '');
  }

  labelsDialog() {
    this.dialog.open(DialogLabelsComponent, {
      data: {
        tagIds: this.item.tags,
        projectId: this.projectId,
      }
    }).afterClosed().subscribe((tagIds: string[]) => {
      if (!tagIds) return;
      this.item.tags = tagIds;
      this.tagsRef?.updateTags(tagIds);
      this.save();
    });
  }

  itemUsersDialog() {
    this.dialog.open(DialogUsersItemComponent, {
      width: 'fit-content',
      data: {
        users: this.item.userIds,
        allUsers: this.allUsers,
      },
    }).afterClosed().subscribe((res: User[]) => {
      if (!res) return;
      this.addedUsers = res;
      const addedUsersIds = this.addedUsers?.map(user => user.id);
      this.item.userIds = addedUsersIds;

      if (!this.item.observers) this.item.observers = [];
      this.item.observers.push(...addedUsersIds);

      this.changeDetector.detectChanges();

      this.save();
    });
  }

  loadUsers() {
    this.addedUsers = [];
    this._item?.userIds?.forEach(userId => {
      this.addedUsers.push(...this.allUsers.filter(user => user.id === userId));
    });
  }

  viewTimes() {
    if (this.item.subtasks && this.item.subtasks.length > 0) this.activeTimes = !this.activeTimes;
  }

  //UPDATE FUNCTIONS
  timeUpdate(itemTime: ItemTime) {
    this.item.time = itemTime;
    this.globalItemTime?.calcTimes();
  }

  subTaskTimeUpdate() {
    this.globalItemTime?.calcTimes();
  }

  dateUpdate(itemDate: ItemDate) {
    this.item.date = itemDate;
    this.itemService.update(this.item).subscribe();
  }

  private updateClone() {
    this._item = structuredClone(this.item);
  }

  save(notification: boolean = true) {
    if (JSON.stringify(this._item) === JSON.stringify(this.item)) return;

    this.item.projectId = this.projectId;
    this.itemService.update(this.item).subscribe(() => {
      this.updateClone();

      if (!notification) return;
      const translation = this.translate.instant('task.task');
      const translation1 = this.translate.instant('task.saved');
      this.snackBar.open(`${translation} "${this.item.name}" ${translation1}`, 'OK', { duration: 2000, });
    });
  }

  timeSave() {
    this.itemService.update(this.item).subscribe({
      next: () => this.analyticsService.itemChangeTime(this.item),
    });
  }

  close() {
    this.dialogRef.close(this.item);
  }

  saveAndClose() {
    this.save();
    this.close();
  }

  shareLink() {
    this.dialog.open(DialogShareLinkComponent, {
      width: 'fit-content',
      data: {
        type: 'Item',
        link: '',
      },
    });
  }

  openItemNameForm(input: any) {
    this.activeForm = !this.activeForm;
    if (this.activeForm) {
      setTimeout(() => input.focus(), input.select(), input.disabled = false);
    }
    this.saveItemName();
  }

  changeItemName(input: any) {
    input.blur();
    this.saveItemName();
  }

  undoItemName() {
    this.item.name = this.itemName;
  }

  saveItemName() {
    if (this.item.name === this.itemName) return;
    this.itemName = this.item.name;
    this.item.projectId = this.projectId;
    this.itemService.update(this.item).subscribe(() => {
      const translation = this.translate.instant('task.titleUpdated')
      this.snackBar.open(translation, "Ok", { duration: 2000 })
    });
  }

  openContextMenu(event: MouseEvent) {
    event.preventDefault();
    // this.menuPosition.x = event.clientX;
    // this.menuPosition.y = event.clientY;
    this.contextMenu?.openMenu(this.menuPosition);
  }

  itemDelete(item: ItemDTO) {
    this.deleteItem.emit(item);
    this.close();
  }

  changePriority(priority: Priority) {
    this.analyticsService.itemChangePriority(this.item, priority);
    this.item.priority = priority.value;
    this.itemService.update(this.item).subscribe(() => {
      const translation = this.translate.instant('priority.updated')
      this.snackBar.open(translation, "Ok", { duration: 2000 })
    });
  }
}
