import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ItemActivityDTO } from 'src/models/activities/itemActivity';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class ItemActivityService extends BaseService {
  constructor(
    private http: HttpClient,
  ) {
    super('/itemActivity');
  }
  static notification: ItemActivityDTO[] = [];
  static notificationObserver: Subject<ItemActivityDTO[]> = new Subject<ItemActivityDTO[]>();
  static unreadedNotifications: number = 0;
  static unreadedNotificationsObserver: Subject<number> = new Subject<number>();

  getAllByItemId = (itemId: string): Observable<ItemActivityDTO[]> => this.http.get<ItemActivityDTO[]>(`${this.baseUrl}/${itemId}`);
  getCountUnreadedByItemId = (itemId: string): Observable<number> => this.http.get<number>(`${this.baseUrl}/countUnreaded/${itemId}`);
  getAllNotification = (): Observable<ItemActivityDTO[]> => this.http.get<ItemActivityDTO[]>(`${this.baseUrl}/unreaded`);
  getCountUnreaded = (): Observable<ItemActivityDTO[]> => this.http.get<ItemActivityDTO[]>(`${this.baseUrl}/countUnreaded`);

  create = (model: ItemActivityDTO, columnId: string): Observable<void> => this.http.post<void>(this.baseUrl, { model, columnId });
  edit = (model: ItemActivityDTO): Observable<void> => this.http.put<void>(this.baseUrl, { model });
  delete = (model: ItemActivityDTO): Observable<void> => this.http.delete<void>(`${this.baseUrl}/${model.id}`);

  doUpdate(notifications: ItemActivityDTO[]) {
    ItemActivityService.notification = notifications;
    ItemActivityService.notificationObserver.next(notifications);
    ItemActivityService.unreadedNotifications = notifications.length;
    ItemActivityService.unreadedNotificationsObserver.next(notifications.length);
  }

  updateNotifications() {
    this.getAllNotification().subscribe(notifications => this.doUpdate(notifications));
  }
}