<div class="date-header">
    <h3>{{'task.date' | translate}}</h3>
</div>
<div class="dateInput">
    <input
        altInputClass="date-control" type="text" mwlFlatpickr 
        [(ngModel)]="dates.start" (ngModelChange)="dateChange()"
        [altInput]="true" 
        [convertModelValue]="true"
        [enableTime]="true" 
        dateFormat="TH:i Y-m-d"
        altFormat="F j, H:i"
        placeholder="fecha inicio"
        mode="single" />
    <input 
        altInputClass="date-control"  type="text" mwlFlatpickr 
        [(ngModel)]="dates.end" (ngModelChange)="dateChange()"
        [altInput]="true"   
        [convertModelValue]="true"  
        [enableTime]="true"
        [minDate]="dates.start ?? firstDate"
        dateFormat="TH:i Y-m-d"
        altFormat="F j, H:i"    
        placeholder="fecha fin"   
        mode="single" />
    <div class="remaining">
        <p [class.hide]="!activeDate" [ngStyle]="{'background-color': backgroundColor}">
            <span *ngIf="remaining < 0">
                {{ 'time.delayed' | translate }}
            </span>
            {{absRemaining}}
            <span [translate]="remaining < 0 ? 'time.days' : 'time.daysLeft'"></span>
        </p>
    </div>
</div>