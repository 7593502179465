<div class="modal-box">
  <h2 *ngIf="shareData.type === 'Project'" mat-dialog-title>{{'modals.share.shareproject' | translate}}</h2>
  <h2 *ngIf="shareData.type === 'Item'" mat-dialog-title>{{'modals.share.shareItem' | translate}}</h2>
  <h2 *ngIf="shareData.type === 'SubItem'" mat-dialog-title>{{'modals.share.shareSubItem' | translate}}</h2>
  <div class="link-input">
    <div >https://linkporcopiar.com</div>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_358_84)">
      <path d="M12 0.75H3C2.175 0.75 1.5 1.425 1.5 2.25V12.75H3V2.25H12V0.75ZM14.25 3.75H6C5.175 3.75 4.5 4.425 4.5 5.25V15.75C4.5 16.575 5.175 17.25 6 17.25H14.25C15.075 17.25 15.75 16.575 15.75 15.75V5.25C15.75 4.425 15.075 3.75 14.25 3.75ZM14.25 15.75H6V5.25H14.25V15.75Z" fill="#A1A1DD"/>
      </g>
      <defs>
      <clipPath id="clip0_358_84">
      <rect width="18" height="18" fill="white"/>
      </clipPath>
      </defs>
      </svg>
  </div>

<div class="modal-buttons flex ai-center jc-flex-end" mat-dialog-actions>
  <button (click)="dialogRef.close()"  class="btn-secondary " cdkFocusInitial mat-dialog-close >Cancel</button> 
  <button class="btn-primary">{{'common.copyLink' | translate}}</button>
</div>

</div>
