<div class="notifications_active">
    <nav class="notifications-menu">
        <h1> {{'Notifications.notification' | translate}} </h1>
        <!-- <div >
               <label (click)="showUnreaded()">
                    <span> {{'Notifications.mensajes' | translate}}</span>
                     <input class="checkNotification" type="checkbox" [(ngModel)]="showAll"/> 
                </label>
            </div>-->
    </nav>

    <div class="content">
        <div class= "bodyItem" *ngFor="let recipient of notificationMap | keyvalue">
            <div class="nameItem" (click)="goToItem(recipient.key)">{{itemMap.get(recipient.key)?.name}} </div>
            <content-notification [notifications]=" recipient.value" />
        </div>
        <ng-container *ngIf="notifications.length === 0 ">
            <p>No hay notificaciones</p>
        </ng-container>
    </div>
</div>