<ng-template [ngIf]="user === undefined" [ngIfElse]="logged">
    <router-outlet />
</ng-template>

<ng-template #logged>
    <div class="mainView">
        <main-menu>
            <router-outlet />
        </main-menu>
    </div>
</ng-template>