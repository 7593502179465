
import { CdkDragDrop, CdkDragEnd, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Conditional } from '@angular/compiler';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResizeEvent } from 'angular-resizable-element';
import * as dayjs from 'dayjs';
import { DialogItemComponent } from 'src/app/project/dialog-item/dialog-item.component';
import { Day } from 'src/models/calendar/calendar';
import { CalendarItemDTO, ItemDTO } from 'src/models/tasks/item';
import { ItemService } from 'src/services/Item/item.service';
import { isDate } from 'util/types';
type Vista = "mes" | "semana" | "dia";

const sizeConstant: number = Object.freeze(1.3);
const itemDays = Object.freeze(1000 * 60 * 60 * 24);

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit {
  constructor(
    private itemService: ItemService,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
  ) { }

  vistaCalendario: Vista = "semana";
  mesActual: Boolean = true;
  date: Date = new Date();
  readonly today: Date = new Date();
  hourNow: string = this.date.toTimeString();
  dias: Day[] = [];
  hours: string[] = [];
  day: Day[] = [];
  pixel?: number | undefined;
  isClicked: Boolean = true;

  ngOnInit() {
    this.dias = this.getDaysInMonth();
    this.loadItems();
    this.hoursOfDay();
  }

  loadItems() {
    this.itemService.getBetweenDates(this.dias[0].date, this.dias[this.dias.length - 1].date).subscribe(items => this.putItemsInCalendar(items, true));
  }

  private putItemsInCalendar(items: ItemDTO[], doUpdate = false) {
    const dayMap: Map<string, CalendarItemDTO[]> = new Map();

    items.forEach(_item => {
      let item = structuredClone(_item);
      const itemStart = item.date!.start;
      const itemEnd = item.date?.end;

      const startDate = new Date(itemStart ?? 0);
      let endDate = new Date(itemEnd ?? itemStart ?? 0);

      const initialDay = startDate.getDate();
      const finalDay = endDate.getDate();
      const dayMonth = this.getStringOfDate(startDate);
      const lastDates = dayMap.get(dayMonth);

      if (initialDay !== finalDay) {
        for (let x = initialDay + 1; x <= finalDay; x++) {
          const dayI = structuredClone(startDate);
          const finalDayI = structuredClone(endDate);
          if (x != finalDay) {
            finalDayI.setHours(23);
            finalDayI.setMinutes(59);
          }
          dayI.setDate(x);
          dayI.setHours(0);
          const dayMonth = this.getStringOfDate(dayI);
          const calendarItem: CalendarItemDTO = {
            ...item,
            startDate: dayI,
            endDate: finalDayI,
          }
          dayMap.set(dayMonth, lastDates ? [...lastDates, calendarItem] : [calendarItem]);
          item = structuredClone(_item);
        }
      }
      if (initialDay !== finalDay) {
        endDate = new Date(startDate);
        endDate.setHours(23);
        endDate.setMinutes(59);
      }
      const calendarItem: CalendarItemDTO = {
        ...item,
        startDate,
        endDate,
      }
      dayMap.set(dayMonth, lastDates ? [...lastDates, calendarItem] : [calendarItem]);
    });

    this.dias.forEach(day => {
      const dayMonth = this.getStringOfDate(day.date);
      const items = dayMap.get(dayMonth) ?? [];
      day.items = [...day.items, ...items];
    });
    if (doUpdate) this.changeDetector.detectChanges();
  }

  private removeItemInCalendar(item2Remove: ItemDTO, doUpdate = false) {
    this.dias.forEach(day => day.items = day.items.filter(item => item.id !== item2Remove.id));
    if (doUpdate) this.changeDetector.detectChanges();
  }

  private updateItemInCalendar(item: ItemDTO) {
    this.removeItemInCalendar(item);
    this.putItemsInCalendar([item]);
    this.changeDetector.detectChanges();
  }

  getStringOfDate(date: Date): string {
    return `${date.getMonth() + 1}/${date.getDate()}`;
  }

  getDaysInMonth(): Day[] {
    const firstDayOfMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    const lastDayOfMonth = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
    const days: Day[] = [];

    const dayOfWeek = firstDayOfMonth.getDay() - 1;
    const daysInPreviousMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();
    for (let i = dayOfWeek; i > 0; i--) {
      const date = new Date(this.date.getFullYear(), this.date.getMonth() - 1, daysInPreviousMonth - i + 1);
      days.push({ date, items: [] });
    }

    for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
      const date = new Date(this.date.getFullYear(), this.date.getMonth(), day);
      days.push({ date, items: [] });
    }

    const lastDayOfWeek = lastDayOfMonth.getDay() - 1;
    for (let i = 1; i < 7 - lastDayOfWeek; i++) {
      const date = new Date(this.date.getFullYear(), this.date.getMonth() + 1, i);
      days.push({ date, items: [] });
    }
    return days;
  }

  changeMonthView(direccion: number) {
    this.date = new Date(this.date.setMonth(this.date.getMonth() + direccion));
    this.dias = this.getDaysInMonth();
    this.mesActual = this.date.getMonth() == this.today.getMonth() ? true : false;
  }

  getWeekDays(day: Date): Day[] {
    const weekStart = new Date(day.getFullYear(), day.getMonth(), day.getDate() - day.getDay() + 1);
    const weekEnd = new Date(day.getFullYear(), day.getMonth(), day.getDate() - day.getDay() + 7);
    const weekDays = this.dias.filter(day => day.date >= weekStart && day.date <= weekEnd);
    return weekDays;
  }

  getDay(day: Date): Day[] {
    const dayStart = new Date(day.getFullYear(), day.getMonth(), day.getDate());
    const listItems = this.dias.filter(day => day.date.getDate() == dayStart.getDate() && day.date.getMonth() == dayStart.getMonth());
    return listItems;
  }

  getItemByDay(day: Date): ItemDTO[] {
    const dayStart = new Date(day.getFullYear(), day.getMonth(), day.getDate());
    const listItems = this.dias.filter(day => day.date.getDate() == dayStart.getDate() && day.date.getMonth() == dayStart.getMonth()).flatMap(day => day.items);
    return listItems;
  }

  getBiggerItemsBetweenHours(items: CalendarItemDTO[], start: number): CalendarItemDTO[] {
    return items.filter(item => start > item.startDate.getTime() && item.endDate.getTime() > start);
  }

  changesDays(direccion: number) {
    if (this.vistaCalendario == "mes") {
      this.date = new Date(this.date.setMonth(this.date.getMonth() + direccion));
      this.dias = this.getDaysInMonth();
      this.mesActual = this.date.getMonth() == this.today.getMonth() ? true : false;
      this.loadItems();

    } else if (this.vistaCalendario == "semana") {
      const moveDays = direccion === 1 ? 7 : -7;
      this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + moveDays);
    } else if (this.vistaCalendario == "dia") {
      const moveDays = direccion === 1 ? 1 : -1;
      this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + moveDays);
    }
  }

  jumpCurrentDay() {
    this.changeMonthView(this.today.getMonth() - this.date.getMonth())
  }

  swapView(vista: Vista) {
    this.vistaCalendario = vista;
  }

  public hoursOfDay() {
    const hoursAm = ["12 AM", "1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 PM", " 1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM"];
    const hours0 = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", " 13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];
    if (this.isClicked) {
      this.hours = hoursAm;
      this.isClicked = false;
    } else {
      this.hours = hours0;
      this.isClicked = true;
    }
  }

  private getAdditionhours(item: CalendarItemDTO, pixels: number): number {
    if (!item.date?.start) return 0;
    const date = new Date(item.date?.start);
    if (pixels != 0) {
      const endDate = new Date(item.date.end ?? item.date.start);
      const hoursDiff = pixels / 54;
      date.setHours(date.getHours() + hoursDiff);
      item.date.start = date.getTime();
      endDate.setHours(endDate.getHours() + hoursDiff);
      item.date.end = endDate.getTime();
    }
    const diff = date.getHours() * 54;
    return diff;
  }

  getItemStyle(items: CalendarItemDTO[], item: CalendarItemDTO, debug: boolean = false): Partial<CSSStyleDeclaration> {
    const startHours = item.startDate.getHours();
    const differenceDays = (item.endDate.getHours() - startHours) * 54 + ((item.endDate.getMinutes() - item.startDate.getMinutes()) * 54 / 60) || 13.5;
    return {
      top: `${startHours * 54}px`,
      height: `${differenceDays}px`,
      ...this.widthOfItem(items, item),
    };
  }

  widthOfItem(items: CalendarItemDTO[], item: CalendarItemDTO): Partial<CSSStyleDeclaration> {
    let widthItem = 95;
    let leftItem = 0;
    let zItem = '0';

    if (items.length > 1) {
      const startTimes = items.map(item => item.date!.end! - item.date!.start!);
      const highestTime = Math.max(...startTimes);
      const bigItem = items.find(item => (item.date!.end! - item.date!.start!) === highestTime);
      const itemStartingSameTime = items.filter(itemFilter => itemFilter.id !== item.id && itemFilter.date!.start === item.date!.start).length;
      const biggerItems = this.getBiggerItemsBetweenHours(items, item.date!.start!).length;

      if (item.id === bigItem?.id) {
        widthItem = 95;
        leftItem = 0;
        zItem = '0';
      } else if (itemStartingSameTime === 0 && biggerItems > 0) {
        widthItem = Math.min(100, 100 / (biggerItems + 1) * sizeConstant);
        leftItem = 10 * biggerItems;
        zItem = '2'
      } else {
        widthItem = Math.min(100, 100 / (itemStartingSameTime + 1) * sizeConstant);
       leftItem = 10;      
        zItem = '1'
      }
    }
    return {
      width: `${widthItem}%`,
      marginLeft: `${leftItem}%`,
      zIndex : `${zItem}`,
    };
  }

  onResizeItem(event: ResizeEvent, item: CalendarItemDTO) {
    const pixelsDown: number = Number(event.edges.bottom);
    const hoursDiff = pixelsDown / 54;
    const minutesDiff = (pixelsDown % 54) / 54 * 60;

    item.endDate.setHours(item.endDate.getHours() + hoursDiff);
    item.endDate.setMinutes(item.endDate.getMinutes() + minutesDiff);
    item.date!.end = item.endDate.getTime();
    this.saveItem(item);
    this.changeDetector.detectChanges();
  }

  drop(event: CdkDragDrop<CalendarItemDTO[]>) {
    // Only when the item to drag it's moved to other day/column
    if (event.previousContainer !== event.container) return;
    const item: CalendarItemDTO = event.container.data[0];
    this.getAdditionhours(item, event.distance.y);
    this.saveItem(item, false);
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.changeDetector.detectChanges();
  }

  dragEnd(event: CdkDragEnd, item: CalendarItemDTO, oldDate: Date) {
    const attributeValue = (event.event.target as any).closest('div[date]').attributes.date!.value;
    const newDate = new Date(attributeValue);
    const diffDays = Math.ceil((newDate.getTime() - oldDate.getTime()) / itemDays);

    item.date!.start = dayjs(item.date!.start).add(diffDays, 'day').valueOf();
    item.date!.end = dayjs(item.date!.end).add(diffDays, 'day').valueOf();
    this.saveItem(item);
  }

  private saveItem(item: CalendarItemDTO, updateCalendar: boolean = true) {
    this.itemService.update(item).subscribe({
      next: () => {
        updateCalendar && this.updateItemInCalendar(item);
      }
    });
  }

  openItem(item: CalendarItemDTO) {
    this.dialog.open(DialogItemComponent, {
      width: '1000px',
      data: { item, projectId: item.projectId },
    }).afterClosed().subscribe(() => {
      this.updateItemInCalendar(item);
    });
  }
}
