import { Component } from '@angular/core';

@Component({
  selector: 'bee-add-member-icon',
  template: `
    <svg width="29" height="28" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="8" width="30" height="30" rx="10" fill="#DADAF0"/>
<path d="M30 1.5C25.8577 1.5 22.5 4.85775 22.5 9C22.5 13.1422 25.8577 16.5 30 16.5C34.1422 16.5 37.5 13.1422 37.5 9C37.5 4.85775 34.1422 1.5 30 1.5ZM33.75 9C33.75 9.41421 33.4142 9.75 33 9.75H31.75C31.1977 9.75 30.75 10.1977 30.75 10.75V12C30.75 12.4142 30.4142 12.75 30 12.75V12.75C29.5858 12.75 29.25 12.4142 29.25 12V10.75C29.25 10.1977 28.8023 9.75 28.25 9.75H27C26.5858 9.75 26.25 9.41421 26.25 9V9C26.25 8.58579 26.5858 8.25 27 8.25H28.25C28.8023 8.25 29.25 7.80228 29.25 7.25V6C29.25 5.58579 29.5858 5.25 30 5.25V5.25C30.4142 5.25 30.75 5.58579 30.75 6V7.25C30.75 7.80228 31.1977 8.25 31.75 8.25H33C33.4142 8.25 33.75 8.58579 33.75 9V9Z" fill="#5755CE"/>
<path d="M7.5 32C7.5 32 6 32 6 30.5C6 29 7.5 24.5 15 24.5C22.5 24.5 24 29 24 30.5C24 32 22.5 32 22.5 32H7.5ZM15 23C16.1935 23 17.3381 22.5259 18.182 21.682C19.0259 20.8381 19.5 19.6935 19.5 18.5C19.5 17.3065 19.0259 16.1619 18.182 15.318C17.3381 14.4741 16.1935 14 15 14C13.8065 14 12.6619 14.4741 11.818 15.318C10.9741 16.1619 10.5 17.3065 10.5 18.5C10.5 19.6935 10.9741 20.8381 11.818 21.682C12.6619 22.5259 13.8065 23 15 23V23Z" fill="#A1A1DD"/>
</svg>

  `,
  styleUrls: ['addMember.icon.sass'],
  standalone: true
})
export default class AddMemberIcon {}
