<mat-toolbar *ngIf="isMobile">
    <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <a routerLink="/project">BeeTask</a>
    <span class="menuSpacer"></span>
    <ng-container *ngTemplateOutlet="menuButtons" />
</mat-toolbar>

<mat-drawer-container class="lateral-menu" autosize hasBackdrop>
    <mat-drawer #drawer>
        <nav class="menu mobile">
            <ng-container *ngTemplateOutlet="menuOptions" />
            <team-selector [teams]="teams" [(selectedTeam)]="selectedTeam" />
        </nav>
    </mat-drawer>
    <mat-drawer-content>
        <nav class="menu" #menu *ngIf="!isMobile">
            <div class="logo">
                <img (click)="toggleMenu()" src="../../../assets/img/logo-beetask.png" alt="logo BeeTask">
                <span [@openClose]="visible ? 'open' : 'closed'" routerLink="/project">BeeTask</span>
            </div>
            <ng-container *ngTemplateOutlet="menuOptions" />
        </nav>
        <div [style.width.px]="contentWidth" #content>
            <ng-container *ngTemplateOutlet="contenido" />
        </div>
    </mat-drawer-content>
</mat-drawer-container>

<ng-template #menuOptions>
    <div class="buttons">
        <button *ngFor="let menu of menus" (click)="closeMenu()" [routerLink]="menu.path"
            [matTooltip]="!visible ? (menu.name | translate) : undefined" matTooltipPosition="right" class="btn-menu">
            <mat-icon>{{menu.icon}}</mat-icon>
            <span [@openClose]="visible ? 'open' : 'closed'">{{menu.name | translate}}</span>
        </button>
    </div>
</ng-template>

<ng-template #contenido>
    <div #horizontalMenu *ngIf="!isMobile" class="menu-box">
        <nav class="menu">
            <team-selector [teams]="teams" [(selectedTeam)]="selectedTeam" />

            <div class="icons">
                <ng-container *ngTemplateOutlet="menuButtons" />
            </div>
        </nav>
    </div>
    <ng-content />
</ng-template>

<ng-template #menuButtons>
    <button mat-icon-button [matBadge]="unreadedNotifications" (click)="openNotifications()" class="user">
        <mat-icon>notifications_active</mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="userConfig" class="user">
        <mat-icon>account_circle</mat-icon>
    </button>
</ng-template>

<mat-menu #userConfig="matMenu">
    <button mat-menu-item (click)="openDialog()">
        <mat-icon>settings</mat-icon>
        <span>{{'mainMenu.settings' | translate}}</span>
    </button>
    <button (click)="logout()" mat-menu-item>
        <mat-icon>logout</mat-icon>
        <span>{{'mainMenu.logout' | translate}}</span>
    </button>
</mat-menu>