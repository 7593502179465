import { Component } from '@angular/core';

@Component({
  selector: 'bee-person-icon',
  template: `
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.8125 14.625C26.8125 16.5644 26.0421 18.4244 24.6707 19.7957C23.2994 21.1671 21.4394 21.9375 19.5 21.9375C17.5606 21.9375 15.7006 21.1671 14.3293 19.7957C12.9579 18.4244 12.1875 16.5644 12.1875 14.625C12.1875 12.6856 12.9579 10.8256 14.3293 9.45428C15.7006 8.08292 17.5606 7.3125 19.5 7.3125C21.4394 7.3125 23.2994 8.08292 24.6707 9.45428C26.0421 10.8256 26.8125 12.6856 26.8125 14.625V14.625Z" fill="#8FEAFE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 19.5C0 14.3283 2.05446 9.36838 5.71142 5.71142C9.36838 2.05446 14.3283 0 19.5 0C24.6717 0 29.6316 2.05446 33.2886 5.71142C36.9455 9.36838 39 14.3283 39 19.5C39 24.6717 36.9455 29.6316 33.2886 33.2886C29.6316 36.9455 24.6717 39 19.5 39C14.3283 39 9.36838 36.9455 5.71142 33.2886C2.05446 29.6316 0 24.6717 0 19.5V19.5ZM19.5 2.4375C16.2868 2.43767 13.139 3.34513 10.4189 5.05544C7.69869 6.76575 5.51675 9.20939 4.12416 12.1051C2.73157 15.0008 2.18494 18.2309 2.54718 21.4236C2.90943 24.6163 4.16582 27.6418 6.17175 30.1519C7.90238 27.3634 11.7122 24.375 19.5 24.375C27.2878 24.375 31.0952 27.3609 32.8283 30.1519C34.8342 27.6418 36.0906 24.6163 36.4528 21.4236C36.8151 18.2309 36.2684 15.0008 34.8758 12.1051C33.4832 9.20939 31.3013 6.76575 28.5811 5.05544C25.861 3.34513 22.7132 2.43767 19.5 2.4375V2.4375Z" fill="#8FEAFE"/>
  </svg>
  `,
  standalone: true
})
export default class PersonIcon {}
