import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ItemActivityDTO } from 'src/models/activities/itemActivity';
import { ItemDTO } from 'src/models/tasks/item';
import { ItemService } from 'src/services/Item/item.service';
import { ItemActivityService } from 'src/services/ItemActivity/itemActivity.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {

  constructor(
    private itemService: ItemService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private location: Location,
  ) { }

  @Output() notificationContent = new EventEmitter<number>();

  showAll: boolean = false;
  notifications: ItemActivityDTO[] = ItemActivityService.notification;
  allNotifications: ItemActivityDTO[] = ItemActivityService.notification;
  textNotifications: string = "";
  itemMap: Map<string, ItemDTO> = new Map<string, ItemDTO>();
  notificationMap: Map<string, ItemActivityDTO[]> = new Map<string, ItemActivityDTO[]>();

  ngOnInit() {
    ItemActivityService.notificationObserver.subscribe(notifications => {
      this.notifications = notifications;
      this.informationNotificationMap();
    });
    this.informationNotificationMap();
  }

  private informationNotificationMap() {
    const tempMap = new Map<string, ItemActivityDTO[]>();
    this.allNotifications.forEach(notificacion => {
      let arr: ItemActivityDTO[] = [];
      if (tempMap.has(notificacion.itemId)) {
        const values = tempMap.get(notificacion.itemId);
        if (values) arr = values;
      }
      arr.push(notificacion);
      tempMap.set(notificacion.itemId, arr);
    });
    this.notificationMap = tempMap;
    this.loadItems();
  }

  private loadItems(): void {
    const tempMap = new Map<string, ItemDTO>();
    const observables: Observable<ItemDTO>[] = [];
    this.notificationMap.forEach((_, itemId) => {
      const observable = this.itemService.get(itemId, false);
      observable.subscribe(item => tempMap.set(itemId, item));
      observables.push(observable);
    });
    forkJoin(observables).subscribe(() => {
      this.itemMap = tempMap;
      this.changeDetector.detectChanges();
    });
  }

  showUnreaded() {
    if (this.showAll) {
      this.notifications = this.allNotifications;
      this.showAll = false;
    }
    else {
      this.notifications = [];
      this.showAll = true;
    }
  }

  numMensajes() {
    this.notificationContent.emit(this.notifications.length);
  }

  async goToItem(itemId: string) {
    
    this.itemService.get(itemId).subscribe({
      next: item => {
        const itemUrl = `/project/${item.projectId}/${itemId}`;
        this.router.navigateByUrl(itemUrl, { skipLocationChange: true });
        this.location.go(itemUrl);
      },
    });
  }
}
