<ng-container [ngSwitch]="output">
  <ng-container *ngSwitchCase="'user'" [ngTemplateOutlet]="user" />
  <ng-container *ngSwitchCase="'project'" [ngTemplateOutlet]="project" />
  <ng-container *ngSwitchCase="'filesize'" [ngTemplateOutlet]="filesize" />
</ng-container>

<ng-template #user>
  <div class="dialog">
    <h1>{{ "upgrade.userTitle" | translate }}</h1>
    <mat-icon>warning</mat-icon>
    <p>{{ "upgrade.userDescription" | translate }}</p>
    <button routerLink="/payment" (click)="close()">
      {{ "upgrade.goTo" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #project>
  <div class="dialog">
    <h1>{{ "upgrade.projectTitle" | translate }}</h1>
    <mat-icon>warning</mat-icon>
    <p>{{ "upgrade.projectDescription" | translate }}</p>
    <button routerLink="/payment" (click)="close()">
      {{ "upgrade.goTo" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #filesize>
  <div class="dialog">
    <h1>{{ "upgrade.filesizeTitle" | translate }}</h1>
    <mat-icon>warning</mat-icon>
    <p>{{ "upgrade.filesizeDescription" | translate }}</p>
    <button routerLink="/payment" (click)="close()">
      {{ "upgrade.goTo" | translate }}
    </button>
  </div>
</ng-template>
