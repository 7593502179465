<div class="time">
    <div class="global-times-header">
        <mat-icon>watch_later</mat-icon>
        <p>{{'time.times' | translate}}</p>
    </div>
    <div class="global-times">
        <div class="global-time-total">
            <h4>{{'time.total' | translate}}</h4>
            <p>{{itemTime.total}}</p>
        </div>
        <div class="global-time-spent">
            <h4>{{'time.spent' | translate}}</h4>
            <p>{{itemTime.spent}}</p>
        </div>
        <div class="global-time-remaining">
            <h4>{{'time.remaining' | translate}}</h4>
            <p>{{timeRemaining}}</p>
        </div>
    </div>
</div>