import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubscriptionDTO, SubscriptionPlanType } from 'src/models/subscription/Subscription.dto';
import { SessionManagerService } from '../SessionManager/session-manager.service';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
  constructor(
    protected sessionManager: SessionManagerService,
    protected http: HttpClient,
  ) {
    super('/payment');
  }

  getSubscription = (teamId: string) => this.http.get<SubscriptionDTO>(`${this.baseUrl}/subscription/${teamId}`);
  getCheckoutLink = (teamId: string, type: SubscriptionPlanType) => this.http.get(`${this.baseUrl}/checkoutLink/${teamId}/${type}`, {responseType: 'text'});
}
